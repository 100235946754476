<template>
  <v-container id="error">
    <v-row>
      <v-col cols="12">
        <h1 class="error--text">{{ error }}</h1>
        <p class="blue-grey--text" v-if="error.includes('40')">
          {{ $t(`error.${error}`) }}
        </p>
        <p class="blue-grey--text" v-if="error.includes('50')">
          {{ $t("error.500") }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="privat"
          height="36px"
          min-width="250px"
          :disabled="timeOut"
          v-if="error !== '403'"
          @click.native="goToAuth()"
          class="white--text"
        >
          {{ $t("action.reboot") }}
          <span v-if="time" class="blue-grey--text ml-6">{{ time }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      loading: false,
      error: "",
      timeOut: true,
      time: 10
    };
  },
  methods: {
    startTime() {
      this.timeInterval = setInterval(() => {
        if (this.time >= 1) {
          this.time--;
        }
        if (this.time === 0) {
          this.timeOut = false;
          clearInterval(this.timeInterval);
        }
      }, 1000);
    },
    goToAuth() {
      this.$store.commit("auth_error");
      this.$router.push("/auth");
    }
  },
  mounted() {
    this.error = this.$route.params.id;
    this.startTime();
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
  }
};
</script>

<style scoped lang="scss">
#error {
  h1 {
    font-size: 52px;
    font-weight: bold;
  }
  p {
    font-size: 22px;
    font-weight: bold;
    white-space: normal;
  }
}
</style>
